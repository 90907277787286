.dialog {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  border: none;
  border-radius: .5rem;
  box-shadow: none;
  z-index: 1040; }
  .dialog .dialog-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    background-color: transparent;
    opacity: .2; }
    .dialog .dialog-close .rapfont {
      font-size: 3rem; }
    .dialog .dialog-close:hover, .dialog .dialog-close:focus {
      color: #000;
      text-decoration: none;
      filter: alpha(opacity=50);
      opacity: .5; }
  .dialog .dialog-content .dialog-header {
    border-bottom: 1px solid #d1d5da;
    padding: 1.5rem 4rem 1.5rem 3rem;
    text-align: left; }
    .dialog .dialog-content .dialog-header .dialog-title {
      margin: 0;
      font-size: 1.8rem; }
  .dialog .dialog-content .dialog-body {
    padding: 1.5rem 3rem; }
  .dialog .dialog-content .dialog-footer {
    text-align: left;
    border-top: 1px solid #d1d5da;
    padding: 1.5rem 3rem; }

.dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  filter: alpha(opacity=50);
  opacity: .5;
  z-index: 1039; }
