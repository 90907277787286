.wrapper {
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 800p; }
  .wrapper .RegisterForm {
    position: fixed;
    left: 50%;
    top: 30%;
    width: 25rem;
    margin-left: -12.5rem;
    margin-top: -135px;
    border: 1px solid #E6E6E6;
    border-radius: .5rem; }
    .wrapper .RegisterForm .header {
      padding: 1.5rem 3rem;
      border-bottom: 1px solid #d1d5da; }
      .wrapper .RegisterForm .header .title {
        font-size: 2rem; }
    .wrapper .RegisterForm .body {
      padding: 1.5rem 3rem; }
    .wrapper .RegisterForm .footer {
      padding: 1.5rem 3rem;
      border-top: 1px solid #d1d5da; }
