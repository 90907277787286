input.parsley-error, input.form-control.parsley-error,
textarea.parsley-error, textarea.form-control.parsley-error {
  color: #333;
  background-color: white;
  border-color: #A62A22; }

.parsley-errors-list {
  list-style: none;
  padding-left: .75rem;
  color: #A62A22;
  margin-top: .75rem;
  margin-bottom: .5rem; }

.parsley-errors-list + .parsley-errors-list {
  margin-top: .5rem; }
