@charset "UTF-8";
@font-face {
  font-family: 'brixfont';
  /* project id 12401 */
  src: url("//at.alicdn.com/t/font_p7lto2nib7h6ko6r.eot");
  src: url("//at.alicdn.com/t/font_p7lto2nib7h6ko6r.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_p7lto2nib7h6ko6r.woff") format("woff"), url("//at.alicdn.com/t/font_p7lto2nib7h6ko6r.ttf") format("truetype"), url("//at.alicdn.com/t/font_p7lto2nib7h6ko6r.svg#iconfont") format("svg"); }

.brixfont {
  font-family: "brixfont" !important; }

@font-face {
  font-family: 'rapfont';
  /* project id 182314 */
  src: url("//at.alicdn.com/t/font_afztm7sue48wipb9.eot");
  src: url("//at.alicdn.com/t/font_afztm7sue48wipb9.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_afztm7sue48wipb9.woff") format("woff"), url("//at.alicdn.com/t/font_afztm7sue48wipb9.ttf") format("truetype"), url("//at.alicdn.com/t/font_afztm7sue48wipb9.svg#rapfont") format("svg"); }

.rapfont {
  font-family: "rapfont" !important; }

@font-face {
  font-family: 'techfont';
  src: url("//g.alicdn.com/mm/zuanshi/20161214.111214.492/app/lib/Oswald-Bold.ttf");
  src: url("//g.alicdn.com/mm/zuanshi/20161214.111214.492/app/lib/Oswald-Bold.ttf?#iefix") format("embedded-opentype"), url("//g.alicdn.com/mm/zuanshi/20161214.111214.492/app/lib/Oswald-Bold.ttf") format("woff"), url("//g.alicdn.com/mm/zuanshi/20161214.111214.492/app/lib/Oswald-Bold.ttf") format("truetype"), url("//g.alicdn.com/mm/zuanshi/20161214.111214.492/app/lib/Oswald-Bold.ttf#FZZDHJW--GB1-0") format("svg"); }

.techfont {
  font-family: 'techfont'; }

.form-control {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 6px 9px;
  border: 1px solid #d1d5da;
  border-radius: 4px;
  box-shadow: none;
  font-size: 12px;
  background-color: white; }
  .form-control:focus {
    border-color: #4A7BF7;
    outline: 0; }

input.form-control,
select.form-control {
  height: 32px; }

input[type="radio"],
input[type="checkbox"] {
  margin-top: 3px; }

.form-horizontal label.control-label {
  padding-top: 7px;
  padding-right: 0; }

.form-horizontal label.radio-inline {
  padding-top: 7px;
  padding-right: 0;
  margin: 0 15px 10px 0; }
  .form-horizontal label.radio-inline input[type=radio] {
    margin-right: 10px; }

@media (max-width: 575px) {
  .form-horizontal label.control-label {
    text-align: left; } }

@media (min-width: 576px) {
  .form-horizontal label.control-label {
    text-align: right; } }

.btn {
  line-height: 1.5; }
  .btn .rapfont {
    line-height: 1rem;
    margin-right: .5rem; }

a.btn.btn-success {
  color: white; }

table.table > thead > tr th {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

table.table > tbody > tr > td {
  vertical-align: middle; }
  table.table > tbody > tr > td .operation {
    visibility: hidden; }

table.table > tbody > tr:hover > td .operation {
  visibility: visible; }

.OpeningScreenAdvertising {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20rem;
  height: 4rem;
  margin-left: -10rem;
  margin-top: -2rem; }
  .OpeningScreenAdvertising .Spin {
    margin: 0;
    padding: 0; }

.card-block {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

button:focus {
  outline: none; }

.hide {
  display: none !important; }

.fake-link {
  color: #4A7BF7;
  cursor: pointer; }
  .fake-link:hover {
    color: #4A7BF7; }

.color-3 {
  color: #333; }

.color-6 {
  color: #666; }

.color-9 {
  color: #999; }

.color-c {
  color: #CCC; }

.color-f {
  color: #FFF; }

.bg-c {
  background-color: #CCCCCC; }

.bg-e6 {
  background-color: #E6E6E6; }

.bg-f0 {
  background-color: #F0F0F0; }

.bg-f5 {
  background-color: #F5F5F5; }

.bg-fa {
  background-color: #FAFAFA; }

.bg-f {
  background-color: #FFFFFF; }

.fontsize-12 {
  font-size: 12px; }

.fontsize-14 {
  font-size: 14px; }

.fontsize-16 {
  font-size: 16px; }

.fontsize-18 {
  font-size: 18px; }

.fontsize-20 {
  font-size: 20px; }

.fontsize-22 {
  font-size: 22px; }

.fontsize-24 {
  font-size: 24px; }

.fontsize-26 {
  font-size: 26px; }

.fontsize-28 {
  font-size: 28px; }

.fontsize-30 {
  font-size: 30px; }

.fontsize-32 {
  font-size: 32px; }

.fontsize-34 {
  font-size: 34px; }

.fontsize-36 {
  font-size: 36px; }

.fontsize-38 {
  font-size: 38px; }

.fontsize-40 {
  font-size: 40px; }

.fontsize-60 {
  font-size: 60px; }

.font-bold {
  font-weight: bold; }

.font-tahoma,
.font-number {
  font-family: "Tahoma"; }

.nowrap {
  white-space: nowrap; }

.m0 {
  margin: 0px; }

.ml0 {
  margin-left: 0px; }

.mr0 {
  margin-right: 0px; }

.mt0 {
  margin-top: 0px; }

.mb0 {
  margin-bottom: 0px; }

.p0 {
  padding: 0px; }

.pl0 {
  padding-left: 0px; }

.pr0 {
  padding-right: 0px; }

.pt0 {
  padding-top: 0px; }

.pb0 {
  padding-bottom: 0px; }

.m1 {
  margin: 1px; }

.ml1 {
  margin-left: 1px; }

.mr1 {
  margin-right: 1px; }

.mt1 {
  margin-top: 1px; }

.mb1 {
  margin-bottom: 1px; }

.p1 {
  padding: 1px; }

.pl1 {
  padding-left: 1px; }

.pr1 {
  padding-right: 1px; }

.pt1 {
  padding-top: 1px; }

.pb1 {
  padding-bottom: 1px; }

.m2 {
  margin: 2px; }

.ml2 {
  margin-left: 2px; }

.mr2 {
  margin-right: 2px; }

.mt2 {
  margin-top: 2px; }

.mb2 {
  margin-bottom: 2px; }

.p2 {
  padding: 2px; }

.pl2 {
  padding-left: 2px; }

.pr2 {
  padding-right: 2px; }

.pt2 {
  padding-top: 2px; }

.pb2 {
  padding-bottom: 2px; }

.m3 {
  margin: 3px; }

.ml3 {
  margin-left: 3px; }

.mr3 {
  margin-right: 3px; }

.mt3 {
  margin-top: 3px; }

.mb3 {
  margin-bottom: 3px; }

.p3 {
  padding: 3px; }

.pl3 {
  padding-left: 3px; }

.pr3 {
  padding-right: 3px; }

.pt3 {
  padding-top: 3px; }

.pb3 {
  padding-bottom: 3px; }

.m4 {
  margin: 4px; }

.ml4 {
  margin-left: 4px; }

.mr4 {
  margin-right: 4px; }

.mt4 {
  margin-top: 4px; }

.mb4 {
  margin-bottom: 4px; }

.p4 {
  padding: 4px; }

.pl4 {
  padding-left: 4px; }

.pr4 {
  padding-right: 4px; }

.pt4 {
  padding-top: 4px; }

.pb4 {
  padding-bottom: 4px; }

.m5 {
  margin: 5px; }

.ml5 {
  margin-left: 5px; }

.mr5 {
  margin-right: 5px; }

.mt5 {
  margin-top: 5px; }

.mb5 {
  margin-bottom: 5px; }

.p5 {
  padding: 5px; }

.pl5 {
  padding-left: 5px; }

.pr5 {
  padding-right: 5px; }

.pt5 {
  padding-top: 5px; }

.pb5 {
  padding-bottom: 5px; }

.m6 {
  margin: 6px; }

.ml6 {
  margin-left: 6px; }

.mr6 {
  margin-right: 6px; }

.mt6 {
  margin-top: 6px; }

.mb6 {
  margin-bottom: 6px; }

.p6 {
  padding: 6px; }

.pl6 {
  padding-left: 6px; }

.pr6 {
  padding-right: 6px; }

.pt6 {
  padding-top: 6px; }

.pb6 {
  padding-bottom: 6px; }

.m7 {
  margin: 7px; }

.ml7 {
  margin-left: 7px; }

.mr7 {
  margin-right: 7px; }

.mt7 {
  margin-top: 7px; }

.mb7 {
  margin-bottom: 7px; }

.p7 {
  padding: 7px; }

.pl7 {
  padding-left: 7px; }

.pr7 {
  padding-right: 7px; }

.pt7 {
  padding-top: 7px; }

.pb7 {
  padding-bottom: 7px; }

.m8 {
  margin: 8px; }

.ml8 {
  margin-left: 8px; }

.mr8 {
  margin-right: 8px; }

.mt8 {
  margin-top: 8px; }

.mb8 {
  margin-bottom: 8px; }

.p8 {
  padding: 8px; }

.pl8 {
  padding-left: 8px; }

.pr8 {
  padding-right: 8px; }

.pt8 {
  padding-top: 8px; }

.pb8 {
  padding-bottom: 8px; }

.m9 {
  margin: 9px; }

.ml9 {
  margin-left: 9px; }

.mr9 {
  margin-right: 9px; }

.mt9 {
  margin-top: 9px; }

.mb9 {
  margin-bottom: 9px; }

.p9 {
  padding: 9px; }

.pl9 {
  padding-left: 9px; }

.pr9 {
  padding-right: 9px; }

.pt9 {
  padding-top: 9px; }

.pb9 {
  padding-bottom: 9px; }

.m10 {
  margin: 10px; }

.ml10 {
  margin-left: 10px; }

.mr10 {
  margin-right: 10px; }

.mt10 {
  margin-top: 10px; }

.mb10 {
  margin-bottom: 10px; }

.p10 {
  padding: 10px; }

.pl10 {
  padding-left: 10px; }

.pr10 {
  padding-right: 10px; }

.pt10 {
  padding-top: 10px; }

.pb10 {
  padding-bottom: 10px; }

.m12 {
  margin: 12px; }

.ml12 {
  margin-left: 12px; }

.mr12 {
  margin-right: 12px; }

.mt12 {
  margin-top: 12px; }

.mb12 {
  margin-bottom: 12px; }

.p12 {
  padding: 12px; }

.pl12 {
  padding-left: 12px; }

.pr12 {
  padding-right: 12px; }

.pt12 {
  padding-top: 12px; }

.pb12 {
  padding-bottom: 12px; }

.m15 {
  margin: 15px; }

.ml15 {
  margin-left: 15px; }

.mr15 {
  margin-right: 15px; }

.mt15 {
  margin-top: 15px; }

.mb15 {
  margin-bottom: 15px; }

.p15 {
  padding: 15px; }

.pl15 {
  padding-left: 15px; }

.pr15 {
  padding-right: 15px; }

.pt15 {
  padding-top: 15px; }

.pb15 {
  padding-bottom: 15px; }

.m20 {
  margin: 20px; }

.ml20 {
  margin-left: 20px; }

.mr20 {
  margin-right: 20px; }

.mt20 {
  margin-top: 20px; }

.mb20 {
  margin-bottom: 20px; }

.p20 {
  padding: 20px; }

.pl20 {
  padding-left: 20px; }

.pr20 {
  padding-right: 20px; }

.pt20 {
  padding-top: 20px; }

.pb20 {
  padding-bottom: 20px; }

.m25 {
  margin: 25px; }

.ml25 {
  margin-left: 25px; }

.mr25 {
  margin-right: 25px; }

.mt25 {
  margin-top: 25px; }

.mb25 {
  margin-bottom: 25px; }

.p25 {
  padding: 25px; }

.pl25 {
  padding-left: 25px; }

.pr25 {
  padding-right: 25px; }

.pt25 {
  padding-top: 25px; }

.pb25 {
  padding-bottom: 25px; }

.m30 {
  margin: 30px; }

.ml30 {
  margin-left: 30px; }

.mr30 {
  margin-right: 30px; }

.mt30 {
  margin-top: 30px; }

.mb30 {
  margin-bottom: 30px; }

.p30 {
  padding: 30px; }

.pl30 {
  padding-left: 30px; }

.pr30 {
  padding-right: 30px; }

.pt30 {
  padding-top: 30px; }

.pb30 {
  padding-bottom: 30px; }

.m32 {
  margin: 32px; }

.ml32 {
  margin-left: 32px; }

.mr32 {
  margin-right: 32px; }

.mt32 {
  margin-top: 32px; }

.mb32 {
  margin-bottom: 32px; }

.p32 {
  padding: 32px; }

.pl32 {
  padding-left: 32px; }

.pr32 {
  padding-right: 32px; }

.pt32 {
  padding-top: 32px; }

.pb32 {
  padding-bottom: 32px; }

.m35 {
  margin: 35px; }

.ml35 {
  margin-left: 35px; }

.mr35 {
  margin-right: 35px; }

.mt35 {
  margin-top: 35px; }

.mb35 {
  margin-bottom: 35px; }

.p35 {
  padding: 35px; }

.pl35 {
  padding-left: 35px; }

.pr35 {
  padding-right: 35px; }

.pt35 {
  padding-top: 35px; }

.pb35 {
  padding-bottom: 35px; }

.m40 {
  margin: 40px; }

.ml40 {
  margin-left: 40px; }

.mr40 {
  margin-right: 40px; }

.mt40 {
  margin-top: 40px; }

.mb40 {
  margin-bottom: 40px; }

.p40 {
  padding: 40px; }

.pl40 {
  padding-left: 40px; }

.pr40 {
  padding-right: 40px; }

.pt40 {
  padding-top: 40px; }

.pb40 {
  padding-bottom: 40px; }

.m50 {
  margin: 50px; }

.ml50 {
  margin-left: 50px; }

.mr50 {
  margin-right: 50px; }

.mt50 {
  margin-top: 50px; }

.mb50 {
  margin-bottom: 50px; }

.p50 {
  padding: 50px; }

.pl50 {
  padding-left: 50px; }

.pr50 {
  padding-right: 50px; }

.pt50 {
  padding-top: 50px; }

.pb50 {
  padding-bottom: 50px; }

.m60 {
  margin: 60px; }

.ml60 {
  margin-left: 60px; }

.mr60 {
  margin-right: 60px; }

.mt60 {
  margin-top: 60px; }

.mb60 {
  margin-bottom: 60px; }

.p60 {
  padding: 60px; }

.pl60 {
  padding-left: 60px; }

.pr60 {
  padding-right: 60px; }

.pt60 {
  padding-top: 60px; }

.pb60 {
  padding-bottom: 60px; }

.m80 {
  margin: 80px; }

.ml80 {
  margin-left: 80px; }

.mr80 {
  margin-right: 80px; }

.mt80 {
  margin-top: 80px; }

.mb80 {
  margin-bottom: 80px; }

.p80 {
  padding: 80px; }

.pl80 {
  padding-left: 80px; }

.pr80 {
  padding-right: 80px; }

.pt80 {
  padding-top: 80px; }

.pb80 {
  padding-bottom: 80px; }

.m100 {
  margin: 100px; }

.ml100 {
  margin-left: 100px; }

.mr100 {
  margin-right: 100px; }

.mt100 {
  margin-top: 100px; }

.mb100 {
  margin-bottom: 100px; }

.p100 {
  padding: 100px; }

.pl100 {
  padding-left: 100px; }

.pr100 {
  padding-right: 100px; }

.pt100 {
  padding-top: 100px; }

.pb100 {
  padding-bottom: 100px; }

.w50 {
  width: 50px; }

.h50 {
  height: 50px; }

.w60 {
  width: 60px; }

.h60 {
  height: 60px; }

.w70 {
  width: 70px; }

.h70 {
  height: 70px; }

.w80 {
  width: 80px; }

.h80 {
  height: 80px; }

.w90 {
  width: 90px; }

.h90 {
  height: 90px; }

.w100 {
  width: 100px; }

.h100 {
  height: 100px; }

.w110 {
  width: 110px; }

.h110 {
  height: 110px; }

.w120 {
  width: 120px; }

.h120 {
  height: 120px; }

.w130 {
  width: 130px; }

.h130 {
  height: 130px; }

.w140 {
  width: 140px; }

.h140 {
  height: 140px; }

.w150 {
  width: 150px; }

.h150 {
  height: 150px; }

.w160 {
  width: 160px; }

.h160 {
  height: 160px; }

.w170 {
  width: 170px; }

.h170 {
  height: 170px; }

.w180 {
  width: 180px; }

.h180 {
  height: 180px; }

.w190 {
  width: 190px; }

.h190 {
  height: 190px; }

.w200 {
  width: 200px; }

.h200 {
  height: 200px; }

.w210 {
  width: 210px; }

.h210 {
  height: 210px; }

.w220 {
  width: 220px; }

.h220 {
  height: 220px; }

.w230 {
  width: 230px; }

.h230 {
  height: 230px; }

.w240 {
  width: 240px; }

.h240 {
  height: 240px; }

.w250 {
  width: 250px; }

.h250 {
  height: 250px; }

.w260 {
  width: 260px; }

.h260 {
  height: 260px; }

.w270 {
  width: 270px; }

.h270 {
  height: 270px; }

.w280 {
  width: 280px; }

.h280 {
  height: 280px; }

.w290 {
  width: 290px; }

.h290 {
  height: 290px; }

.w300 {
  width: 300px; }

.h300 {
  height: 300px; }

.w310 {
  width: 310px; }

.h310 {
  height: 310px; }

.w320 {
  width: 320px; }

.h320 {
  height: 320px; }

.w340 {
  width: 340px; }

.h340 {
  height: 340px; }

.w350 {
  width: 350px; }

.h350 {
  height: 350px; }

.w360 {
  width: 360px; }

.h360 {
  height: 360px; }

.w370 {
  width: 370px; }

.h370 {
  height: 370px; }

.w380 {
  width: 380px; }

.h380 {
  height: 380px; }

.w390 {
  width: 390px; }

.h390 {
  height: 390px; }

.w400 {
  width: 400px; }

.h400 {
  height: 400px; }

.w500 {
  width: 500px; }

.h500 {
  height: 500px; }

.w600 {
  width: 600px; }

.h600 {
  height: 600px; }

.w800 {
  width: 800px; }

.h800 {
  height: 800px; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", Georgia, "Times New Roman", Arial, sans-serif;
  -webkit-font-smoothing: antialiased; }

html, body {
  height: 100%; }

#root {
  height: 100%; }
  #root > .Routes {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    #root > .Routes > .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
      #root > .Routes > .body > .Spin {
        align-items: center;
        justify-content: center; }
      #root > .Routes > .body > * {
        flex-grow: 1;
        display: flex;
        flex-direction: column; }
        #root > .Routes > .body > * > .body {
          flex-grow: 1; }

button, input, optgroup, select, textarea {
  font-size: 1.2rem;
  font-family: "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", Georgia, "Times New Roman", Arial, sans-serif;
  -webkit-font-smoothing: antialiased; }

a, a:hover, a:focus, a:active, a:visited {
  outline: 0;
  text-decoration: none; }

a[disabled], a.disabled {
  pointer-events: none;
  cursor: not-allowed; }

a.text-decoration:hover {
  border-bottom: 1px solid #4A7BF7; }

pre {
  margin: 0;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5da;
  border-radius: 0.4rem;
  background-color: #F0F0F0;
  white-space: pre-wrap; }

.btn-top {
  position: fixed;
  right: 150px;
  bottom: -1px;
  width: 90px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  padding: 16px;
  z-index: 99999;
  background-color: #ffffff;
  border: 1px solid #DDDDDD;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.mr8 {
  margin-right: 8px; }
