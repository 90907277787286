@charset "UTF-8";
.Popover {
  position: relative; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 30rem;
  display: block;
  padding: 0;
  border: 1px solid #d1d5da;
  border-radius: 4px;
  box-shadow: 0 6px 8px rgba(51, 51, 51, 0.08);
  font-family: "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", Georgia, "Times New Roman", Arial, sans-serif;
  background-color: white; }
  .popover.top {
    margin-top: -10px; }
  .popover.right {
    margin-left: 10px; }
  .popover.bottom {
    margin-top: 10px; }
  .popover.left {
    margin-left: -10px; }
  .popover .arrow {
    display: none; }
  .popover .popover-title {
    margin: 0;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ebebeb;
    border-radius: .4rem .4px 0 0;
    font-size: 14px;
    background-color: #fafafa; }
  .popover .popover-content {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 12px;
    line-height: 22px; }
