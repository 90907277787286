.Spin {
  padding: 100px 0; }

.three-bounce {
  position: relative;
  text-align: center;
  top: 50%;
  bottom: 50%;
  margin-top: -9px; }

.three-bounce > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #4A7BF7;
  top: 50%;
  margin-top: -9px;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out; }

.three-bounce .one {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.three-bounce .two {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
